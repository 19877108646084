import React from "react";
import { isChrome, isFirefox, isIeEdge, isSafari, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/tilt.module.scss";
import Data from "./data/tilt.json";

class Tilt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: "none",
      pageData: props.data,
      data: Data,
      isLoading: true
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    else if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
   else if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
    else if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
    else if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.browsers) {
      this.showBrowsers(this.props.browsers);
    } else {
      this.setState({ display: "block" });
    }
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();
        this.state.isSafari = isSafari();
        if (isChrome()) {
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({ data: browserData });
        }
        if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
        if (isSafari()) {
          const browserData = Object.assign(this.state.data, this.state.data.safari);
          this.setState({ data: browserData });
        }
        if (isIeEdge()) {
          const browserData = Object.assign(this.state.data, this.state.data.edge);
          this.setState({ data: browserData });
        }
        if (typeof window != "undefined") {
            window.mapParams();
        }
      });
    }

    window.addEventListener("load", () => {
      this.setState({ isLoading: false });
    });

      //OS Detection
      document.querySelectorAll('.animCTA').forEach(p => {

          p.innerText = this.state.data.button;
  });

  }

  render() {
    return (
      <>
      <main id={Styles.tiltLp}>
          <section>
            <header>
              <img alt="Carbonate Logo" className={Styles.topLogo} src={this.state.data.topLogo} />
            </header>
              <h1 className = "hc_param">{this.state.data.headline}</h1>
              <h2 className={Styles.subheadline}>{this.state.data.subheadline}</h2>
              <p className={Styles.paragraph}>{this.state.data.paragraph}</p>
              <button className={Styles.cta} onClick = {() => window.triggerInstall()}>{this.state.data.mainCTA}</button>
              {this.props.children}
          </section>
        </main>
      </>
    );
  }
}

export default Tilt;
